.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 3.5vw;
    background-color: white;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    height: 80px;
}

.header-logo img {
    height: 40px; /* Adjust as needed */
}

.header-profile {
    display: flex;
    align-items: center;
}

.header-profile .account-button {
    background: none;
    border: solid 1px #ccc;
    border-radius: 40px;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.profile-dropdown {
    position: absolute;
    right: .5vw;
    top: 80px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .profile-dropdown  {
        position: relative;
        right: 0;
        top: 0;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 0px;
        padding: 15px;
        box-shadow: 0;
        width: 100vw;
        text-align: left;
    }
}

.grey-icon {
    color: #404040; /* or use a specific hex code for the shade of grey you want */
}