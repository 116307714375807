

.google-search-result {
    margin-top: 20px;
    margin-bottom: 20px;
    p {
        font-size: 1.2rem;
        color: #333;
        margin: 2px;
    }
}