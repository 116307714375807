

.home-graphic {
    height: 500px;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    margin-bottom: 10vh;
}

.horizonal-panel-holder {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    opacity: 0.9;
}

.horizonal-panel {
    height: 200px;
    width: 300px;
    background-color: white;
    border-radius: 30px;
    padding: 15px;
}

.horizonal-home-graphic-img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: '100%';
    z-index: -1;
}

@media screen and (max-width: 600px) {
    .home-graphic {
        height: 600px;
    }
    .horizonal-panel-holder {
        flex-direction: column;
        padding: 5vw;
    }
    .horizonal-panel {
        height: 150px;
        width: 90vw;
        padding: 10px;
        margin-bottom: 20px;
    }
    .horizonal-home-graphic-img {
        width: 100%;
    }
}