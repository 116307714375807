
.form-preview-container {
  height: 150px;
  width: 350px;
  max-width: 95%;
  border: solid;
  border-color: silver;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  text-align: left;
  display: inline-block;
  margin-right: 20px;
}

.todo-holder {
  width: 80vw;
  text-align: left;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
}

.todo-list {
  display: flex
}

.todo-description {
  font-weight: 300;
  margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .todo-list {
    flex-direction: column;
  }

  .form-preview-container {
    margin-bottom: 20px;
  }

  .todo-holder {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}