

.white-rounded {
    border-radius: 20px;
    border-width: .5px;
    cursor: pointer;
    background-color: white;
    color: #333333;
    font-weight: 400; 
    align-items: center;
    text-align: center;
}

.white-rounded:hover {
    font-weight: 600;
    color: #666666;
}