/* Style the dropdown button */
.dropdown-toggle {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
    box-sizing: border-box;
    display: inline;
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    cursor: pointer; /* Add a pointer cursor on hover */
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-menu {
    display: none; /* Hide the dropdown */
    position: absolute;
    background-color: #f9f9f9; /* White background */
    min-width: 160px; /* Set a min-width */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add a shadow */
    z-index: 1; /* Place it on top */
  }
  
  /* Links inside the dropdown */
  .dropdown-item {
    color: black; /* Black text color */
    padding: 12px 16px; /* Some padding */
    text-decoration: none; /* Remove underline */
    display: block; /* Display block */
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-item:hover {
    background-color: #f1f1f1; /* Light grey background on hover */
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-menu {
    display: block; /* Display the dropdown */
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropdown-toggle {
    background-color: #3e8e41; /* Darker green background on hover */
  }
  