

.inv-container {
    padding-top: 20px;
    text-align: left;
    margin: auto;
    margin-bottom: 100px;
}

.inv-container .container-header {
    border-bottom: none;
}

.icontainer {
    /*border: 1px solid silver;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px #AAAAAA;
    padding-top: 20px;*/
    text-align: left;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 100px;
}

.container-header {
    border-bottom: 1px solid silver;
    /*
    padding-left: 50px;
    padding-right: 50px;
    */
    margin-bottom: 20px;
}

.horizontal {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.container-header h1 {
    font-size: 2.5em;
    font-weight: 500;
    margin-bottom: 2px;
}


@media screen and (max-width: 600px) {
    .container-body {
        padding-left: 25px;
        padding-right: 25px;
    }
}