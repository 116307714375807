


.form h2 {
    font-weight: 500;
    margin-bottom: 2px;
}

.form-container {
    width: 70%;
    margin: auto;
    text-align: left;
}

.link-button {
    background: none;
    color: grey;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.link-button:hover {
    color: black;
}

.form-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover; /* Maintain aspect ratio and cover the area */
    object-position: center; /* Center the image within the element */
}

img.form-image {
    transition: filter 0.3s ease; /* Smooth transition for the filter effect */
}

img.form-image:hover {
    filter: brightness(50%); /* Adjust brightness to shade the image */
}


.editing-label {
    font-size: 22px;
    font-weight: 500;
    margin-top: 2px
}

.editing-description {
    color: #555555;
    font-size: 18px;
    margin-bottom: 14px;
}

.text-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 15px;
    margin-bottom: 5px;
    margin-top: 3px;
    box-sizing: border-box;
    display: inline;
    width: 100%;
    height: 50px;
    font-size: 20px;
}

.input-body {
    width: 90%
}

.form-button-blank {
    border: None;
    cursor: pointer;
    background: none;
}

.form-input {
    margin-bottom: 10px
}

.form-button-holder {
    position: absolute;
    top: calc(100vh - 130px);
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    max-height: 5%;
}

.form-button {
    margin: 2px;
    padding: 4px 8px;
    border-radius: 4px;
    border: None;
    cursor: pointer;
}

.form-button .aligned {
    margin-bottom: 4px;
}

.form-button:hover {
    background-color: silver;
}

.cancel-button {
    background-color: white;
    border: None;
    color: #333333;
    font-weight: 400;
    cursor: pointer;
}

.cancel-button:hover {
    font-weight: 600;
    color: #666666;
}

.form-next-button {
    color: white;
    background-color: black;
    margin-right: 100px;
    width: 200px;
    height: 50px;
    border-radius: 5px;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.form-back-button {
    color: black;
    background-color: white;
    text-decoration: underline;
    margin-left: 100px;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    border: none;
    font-size: 20px; 
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .input-body {
        width: 100%;
    }

    .form-container {
        width: 100%;
    }
    
    .form-next-button {
        margin-right: 2px;
    }

    .form-back-button {
        margin-left: 2px;
    }

    .form-button-holder {
        top: calc(100vh - 80px);
        height: 80px;
    }
}
