.autocomplete-dropdown-container {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  