.page-container {
    width:70%;
    margin: auto;
    padding: 20px;
    text-align: center;
    height: calc(100vh - 80px); /* 80px is the height of the header */
    overflow: scroll;
}

@keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-100%);
      opacity: 0;
    }
}

.slide-out {
    animation: slideOut 0.5s ease-out forwards;
}

.slide-in {
    animation: slideIn 0.5s ease-out forwards;
}

@media screen and (max-width: 600px) {
    .page-container {
        width: 90%;
    }
}