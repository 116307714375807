.card {
    width: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 5px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.selected {
    border: 2px solid #000;
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 16px;
}

.card-title {
    margin: 0 0 8px 0;
    font-size: 20px;
}

.card-description {
    margin: 0;
    color: #666;
    font-size: 16px;
}
