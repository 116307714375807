
.section-one {
    height: 600px;
    padding-top: 12vh;
    padding-left: 10vw; 
    display: flex;
    flex-direction: row;
}

.section-one-header {
    width: 75%;
}

.catch-phrase {
    font-size: 90px;
    font-weight: 500;
    text-align: left;
    color: #111177;
    line-height: 1.1;
}

.sub-catch-phrase {
    font-size: 30px;
    font-weight: 400;
    text-align: left;
    color: #060672;
    line-height: 1.1;
}

.section-two {
    background-color: #F2F6F6;
    height: 450px;
    padding: 40px
}

.section-two h1 {
    color: #393939;
    margin-bottom: 50px;
}

.section-two .info-button-holder {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.info-button {
    width: 400px;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #e6e6e6;
    padding: 15px;
    cursor: pointer;
}

.merchant-section {
    background-color: #e8f4ea;
    padding-top: 30px;
}

.storefront-section {
    background-color: #fffff5;
    padding-top: 30px;
}

.panel-holder {
    display: flex;
    flex-direction: row;
    padding: 75px;
}

.panel {
    position: relative;
    width: 50%
}

.panel ul {
    text-align: left;
}

.panel-back-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.info-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 250px;
    background-color: white;
    border-radius: 30px;
    border: 2px solid #e6e6e6;
    padding: 18px;
    margin: auto;
    margin-top: 50px;
    color: #393939;
}

.info-panel p {
    font-size: 14px;
    margin-top: 2px;
    text-align: left;
}

.info-button:hover {
    border: 2px solid blue;
}


.signup-button {
    background: white;
    border: solid 1px #ccc;
    border-radius: 40px;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    width: 200px;
    height: 50px;
    margin-top: 20px;

}

.benefit-panel {
    width: 40%;
    margin: auto;
    margin-top: 0;
}

.benefit-panel h1 {
    margin-top: 0;
}

.merchant-section li {
    font-size: 16px;
    margin-bottom: 5px;
}

.storefront-section li {
    font-size: 16px;
    margin-bottom: 5px;
}


@media screen and (max-width: 768px) {
    .catch-phrase  {
        font-size: 70px;
    }

    .section-two {
        flex-direction: column;
        height: 700px;
    }

    .info-button-holder {
        flex-direction: column;
    }

    .info-button {
        margin-bottom: 30px;
    }

    .panel-holder {
        flex-direction: column;
        padding: 30px;
    }

    .panel {
        width: 100%;
        margin-bottom: 10vh;
    }

    .benefit-panel {
        width: 95%;
    }
}

